import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Header from '../../components/Header';
import {
  BrowserMultiFormatReader,
  DecodeHintType,
  BarcodeFormat,
} from '@zxing/library';
import useSWR from 'swr';

export default function FoodPage() {
  const [codeReader, setCodeReader] = useState<
    BrowserMultiFormatReader | undefined
  >();
  const [deviceIDs, setDeviceIDs] = useState<string[]>([]);
  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string>('');

  const { data: product, isValidating: isProductValidating } = useSWR(
    code ? `https://world.openfoodfacts.org/api/v0/product/${code}.json` : null,
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    const codeReader = new BrowserMultiFormatReader();

    /*const hints = new Map();
    const formats = [
      BarcodeFormat.CODABAR,
      BarcodeFormat.CODE_39,
      BarcodeFormat.CODE_93,
      BarcodeFormat.CODE_128,
      BarcodeFormat.EAN_8,
      BarcodeFormat.EAN_13,
      BarcodeFormat.ITF,
      BarcodeFormat.UPC_A,
      BarcodeFormat.UPC_E,
      BarcodeFormat.UPC_EAN_EXTENSION,
    ];
    hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
    codeReader.hints = hints;*/

    setCodeReader(codeReader);

    console.log('ZXing code reader initialized');
    codeReader
      .listVideoInputDevices()
      .then((videoInputDevices: MediaDeviceInfo[]) => {
        const ids: string[] = [];

        console.log('videoInputDevices', videoInputDevices);
        for (let device of videoInputDevices) {
          ids.push(device.deviceId);
        }

        setDeviceIDs(ids);
      });
  }, []);

  function startDecoding(deviceId: string) {
    if (codeReader == null) {
      return;
    }

    codeReader.decodeFromVideoDevice(deviceId, 'video', (result, error) => {
      console.log('result', result);
      if (result) {
        setCode(result.getText());
      }
      if (error) {
        setError(error.message);
      }
    });
    console.log(`Started continous decode from camera with id ${deviceId}`);
  }

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col>
            <p>Code: {code}</p>
            <p>
              Produkt: {isProductValidating ? '...' : product?.product?.brands}{' '}
              {product?.product?.product_name_de ||
                product?.product?.product_name}
            </p>
            <p>{error}</p>

            <p>
              <video id="video" width={250}></video>
            </p>

            {codeReader &&
              deviceIDs.map((deviceId, index) => (
                <Button
                  key={index}
                  variant="info"
                  onClick={() => startDecoding(deviceId)}
                >
                  <FontAwesomeIcon
                    icon="barcode"
                    style={{ marginRight: '10px' }}
                  />
                  Barcode scannen ({index})
                </Button>
              ))}
          </Col>
        </Row>
      </Container>
    </>
  );
}
