import React from 'react';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { NavItem } from '../types';

const navItems: NavItem[] = [
  {
    title: 'Mein Tag',
    path: '/',
  },
  {
    title: 'Gerichte',
    path: '/meals',
  },
  {
    title: 'Lebensmittel',
    path: '/food',
  },
];

export default function Header() {
  const history = useHistory();

  return (
    <Container>
      <Row>
        <Col>
          <Nav variant="pills" style={{ margin: '10px 0' }}>
            {navItems.map((item, i) => {
              return (
                <Nav.Item key={i}>
                  <Nav.Link
                    href={`#${item.path}`}
                    active={history.location.pathname === item.path}
                  >
                    {item.title}
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
        </Col>
      </Row>
    </Container>
  );
}
