import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faClock,
  faFire,
  faPlus,
  faMinus,
  faChevronLeft,
  faChevronRight,
  faBarcode,
} from '@fortawesome/free-solid-svg-icons';
import './assets/bootstrap-dark.min.css';
import DayPage from './pages/DayPage';
import MealPage from './pages/Meals/MealPage';
import FoodPage from './pages/FoodPage';

library.add(
  faClock,
  faFire,
  faPlus,
  faMinus,
  faChevronLeft,
  faChevronRight,
  faBarcode
);

function App() {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" component={DayPage} />
        <Route exact path="/meals" component={MealPage} />
        <Route exact path="/food" component={FoodPage} />
      </Switch>
    </HashRouter>
  );
}
export default App;
