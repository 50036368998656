import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Row,
} from 'react-bootstrap';
import styled from 'styled-components';
import Header from '../../components/Header';

const CardHeaderIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
  color: #666;
`;

const ButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

export default function MealPage() {
  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col>
            <Accordion>
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="0"
                  style={{ cursor: 'pointer', userSelect: 'none' }}
                >
                  <Row>
                    <Col>Kichererbsenfussili mit Tomatensoße und Parmesan</Col>
                    <Col md="auto">
                      <CardHeaderIcon icon="fire" />
                      700 kcal
                    </Col>
                    <Col md="auto">
                      <CardHeaderIcon icon="clock" />9 Tage
                    </Col>
                  </Row>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Button variant="success">
                      <ButtonIcon icon="plus" /> Zu meinem Tag hinzufügen
                    </Button>
                    <DropdownButton
                      variant="dark"
                      title=""
                      style={{ float: 'right' }}
                    >
                      <Dropdown.Item href="#/action-1">
                        <ButtonIcon icon="minus" /> Entfernen
                      </Dropdown.Item>
                    </DropdownButton>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="1">
                  Click me!
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>Hello! I'm another body</Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  );
}
