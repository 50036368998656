import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/de';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Header from '../../components/Header';

export default function DayPage() {
  const [day, setDay] = useState<Dayjs>(dayjs);

  useEffect(() => {
    console.log('daychange');
  }, [day]);

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col md="auto">
            <Button
              variant="dark"
              onClick={() => setDay(day.subtract(1, 'day'))}
            >
              <FontAwesomeIcon icon="chevron-left" />
            </Button>
          </Col>
          <Col style={{ textAlign: 'center' }}>
            {day.locale('de').format('dd, DD.MM.YYYY')}
          </Col>
          <Col md="auto">
            <Button variant="dark" onClick={() => setDay(day.add(1, 'day'))}>
              <FontAwesomeIcon icon="chevron-right" />
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
